import { Button, Container, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { NextPage } from 'next';
import Link from 'next/link';
import React from 'react';
import { ErrorPageIcon } from 'svgs';
import { SEO } from 'templates/layout';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: theme.spacing(10, 0),
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(20, 0),
    },
  },
  icon: {
    maxWidth: 250,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      maxWidth: 405,
    },
  },
  link: {
    marginTop: theme.spacing(8),
    fontSize: 14,
    [theme.breakpoints.up('sm')]: {
      fontSize: 18,
      padding: theme.spacing(3, 7),
    },
  },
  h1: {
    color: theme.palette.primary.main,
    fontSize: 120,
    [theme.breakpoints.up('sm')]: {
      fontSize: 235,
    },
  },
  h3: {
    marginBottom: theme.spacing(3),
  },
  h4: {
    fontWeight: 400,
    color: theme.palette.text.primary,
    marginBottom: theme.spacing(5),
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(10),
    },
  },
  align: {
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      textAlign: 'left',
    },
  },
}));
const ErrorPage404: NextPage = () => {
  const classes = useStyles();
  return (
    <Container maxWidth={false} fixed>
      <SEO title="Page not found" />
      <Grid container alignItems="center" justifyContent="center" className={classes.root}>
        <Grid item lg={5} md={6} xs={12} className={classes.align}>
          <ErrorPageIcon className={classes.icon} />
        </Grid>
        <Grid item lg={5} md={6} xs={12} className={classes.align}>
          <Typography className={classes.h1} variant="h1">
            404
          </Typography>
          <Typography className={classes.h3} variant="h3">
            Looks like you’re lost
          </Typography>
          <Typography className={classes.h4} variant="h4">
            The page you are looking for is not available!
          </Typography>
          <Link href="/" passHref>
            <Button variant="contained" color="primary">
              Home
            </Button>
          </Link>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ErrorPage404;
